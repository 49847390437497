<template lang="pug">
div(v-if='warranty')
  template(v-if='showItemPrice')
    div(class='whitespace-nowrap') + {{ formatCents(warranty.price) }}
  template(v-else)
    div(class='whitespace-nowrap') + {{ formatCents(warranty.subtotal) }}
  div(class='font-normal text-xs') Protection Plan
</template>

<script>
export default defineNuxtComponent({
  name: 'ExtendPrice',
  props: {
    warranty: {
      type: Object,
      required: true,
    },
    showItemPrice: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
