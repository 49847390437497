<template lang="pug">
div(v-if='installation')
  div(v-if='showItemPrice' class='whitespace-nowrap') + {{ formatCents(price) }}
  div(v-else class='whitespace-nowrap') + {{ formatCents(installation.subtotal) }}
  div(class='whitespace-nowrap font-normal text-xs') Installation
</template>

<script>
export default defineNuxtComponent({
  name: 'InstallernetPrice',
  props: {
    installation: {
      type: Object,
      required: true,
    },
    showItemPrice: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    price() {
      return this.installation.regularPrice !== this.installation.sellPrice
        ? this.installation.sellPrice
        : this.installation.regularPrice
    },
  },
})
</script>
